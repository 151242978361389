
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from "vue";
import Collection from "@/components/list/items/Collection.vue";
import Loading from "@/components/common/Loading.vue";
export default defineComponent({
  name: "ChooseCollections",
  components: {
    Collection,
    Loading,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    showSelf: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    collectionMore: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const viewMore = ref();
    const selectIndex = ref(-1);
    const handleSave = () => {
      const selectedOpt = props.options.filter((it:any,i:number) => props?.isMultiple ? it.checked==true : i===selectIndex.value);
      emit('update:selectOptions', selectedOpt);
      emit('update:showSelf', false);
    };
    const handleCollection = (item:any, index:number) => {
      item.checked = !item.checked
      if (!props?.isMultiple) {
        selectIndex.value = index;
      }
    };
    let ob: any;
    onMounted(() => {
      ob = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio === 0) return;
          if (props?.collectionMore) {
            emit('loadCollection');
          }
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(viewMore.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });
    return {
      handleSave,
      handleCollection,
      selectIndex,
      viewMore
    };
  },
});
