import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9362db76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex-grow flex-shrink text-14-16 items-center whitespace-nowrap overflow-x-hidden overflow-y-visible hidden-scrollbar overflow-ellipsis mr-3" }
const _hoisted_3 = {
  key: 0,
  class: "absolute w-full left-0 right-0 mt-2 border border-solid border-n3 rounded-lg bg-n0 z-40 max-h-64 overflow-y-auto py-3"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "whitespace-nowrap overflow-hidden overflow-ellipsis flex-grow flex-shrink text-14-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex box-border items-center justify-between border border-solid border-n3 rounded-lg cursor-pointer min-w-30 px-4 py-3 hover-animation hover:bg-solid", { 'bg-gray-679': _ctx.showOptions }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleShowOptions && _ctx.handleShowOptions(...args)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value?.[_ctx.showOptionKey]), 1),
      _createElementVNode("i", {
        class: _normalizeClass(["icon-down1 font-normal transform text-white scale-2/3 relative top-px", { '-rotate-180':_ctx.trueShowOptions}]),
        style: {"font-size":"12px","line-height":"12px"}
      }, null, 2)
    ], 2),
    (_ctx.trueShowOptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["px-4 py-3 flex items-center justify-between text-14-16 cursor-pointer hover-animation hover:bg-modal", {
          'bg-gray-679 text-blue-2cc font-medium': item.code === _ctx.value.code,
        }]),
              onClick: _withModifiers(($event: any) => (_ctx.handleSelect(item)), ["stop"]),
              key: index
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item[_ctx.showOptionKey]), 1)
            ], 10, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}