
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  name: "SelectOption",
  components: {},
  props: {
    showOptionKey: {
      type: String,
      default: "name",
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showSelectIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    showName: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    
    const showOptions = ref(false);
    const trueShowOptions = ref(false);
    const selectIndex = ref(0);
    const checked = ref(props.value);
    watch(checked, (nv, ov) => {
      emit("update:value", nv);
    });
    watch(
      () => [props.showName],
      () => {
        if (!props.showName || props.name != props.showName) {
          showOptions.value = false;
        }

        trueShowOptions.value =
          showOptions.value && props.name == props.showName;
      }
    );
    watch(showOptions, () => {
      trueShowOptions.value = showOptions.value && props.name == props.showName;
    });

    const timer:any = ref(null);
    const handleMouseleave = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      timer.value = setTimeout(() => {
        showOptions.value = false;
      }, 500);
    };

    const handleMouseenter = () => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
      }
      showOptions.value = true;
    };
    const handleSelect = (item:any) => {
      showOptions.value = false;
      trueShowOptions.value = false;
      emit("update:value", item);
    };
    const handleShowOptions = () => {
      showOptions.value = !showOptions.value;
      if (!showOptions.value) {
        trueShowOptions.value = false;
      }
      emit("update:showName", props?.name);
    };
    return {
      showOptions,
      trueShowOptions,
      selectIndex,
      checked,
      handleMouseleave,
      handleMouseenter,
      handleSelect,
      handleShowOptions,
    };
  },
});
