
import { defineComponent } from "vue";
import { getQueryString } from "@/utils/url";
import { kmbNumber, formatSmallNumber } from "@/utils/formatNumber";
import { modifyImgSize } from "@/utils/imgSize";
import { formatTime } from "@/utils/formatTime";
import { useRouter } from "vue-router";
import LZString from "lz-string";

export default defineComponent({
  name: "History",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const getTransactionTypeDisplay = () => {
      if (
        props.item?.transferType === "mint" &&
        Number(props.item?.price) === 0
      ) {
        return "Free mint";
      } else {
        return props.item?.transferType === "sold" ? "Receive" : "Cost";
      }
    };
    const getImgSrc = (it: any) => {
      return modifyImgSize(
        `${process.env.VUE_APP_IMG_DOMAIN}/res/eth/${it?.contract}/${it?.tokenId}?size=76x76&format=webp&needOrigin=1`,
        "_64x64"
      );
    };
    const handleShare = () => {
      console.log("share");
    };
    const router = useRouter();
    const toItemDetailPage = (item: any) => {
      let queryArray: any = [];
      let queryString = "";
      item?.groupItems?.forEach((it: any, index: number) => {
        if (queryString.length > 10000) return;
        if (index === 0) {
          queryString += it?.contract + "@";
          queryString += it?.tokenId;
        } else {
          queryString += "-" + it?.tokenId;
        }
      });
      const encodeData = LZString.compressToBase64(queryString);
      let routeData = router.resolve({
        name: "itemDetail",
        query: { data: encodeData },
      });
      window.open(routeData.href, "_self");
    };
    return {
      getQueryString,
      kmbNumber,
      getImgSrc,
      modifyImgSize,
      formatTime,
      getTransactionTypeDisplay,
      formatSmallNumber,
      handleShare,
      toItemDetailPage,
    };
  },
});
