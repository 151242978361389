
import {
  defineComponent,
  ref,
  nextTick,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Loading from "@/components/Loading.vue";
import ListItems from "@/components/list/ListItems.vue";
import FilterCollection from "@/components/list/items/FilterCollection.vue";
import HistoryItem from "@/components/list/items/HistoryItem.vue";
import ChooseCollections from "@/components/ChooseCollections.vue";
import SelectOption from "@/components/SelectOption.vue";
import Toast from "@/components/toast/index";
import { useRoute } from "vue-router";
import {
  getAccountWallet,
  getAccountWalletContracts,
  getAccountContracts,
  getAccountHistory,
  getAccountNfts,
} from "@/apis/tracking";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
import { decimalizeNumber, amountFormat } from "@/utils/numberUtils";
import { getLongStringShow, copyString } from "@/utils/formatString";
import { historyReplaceStateQuery } from "@/utils/url";

export default defineComponent({
  name: "WalletDetail",
  components: {
    ListItems,
    SelectOption,
    ChooseCollections,
    FilterCollection,
    HistoryItem,
    Loading,
    // ViewMore,
  },
  setup() {
    const loading = ref(true);
    const route = useRoute();
    const addr = route?.params?.addr;
    const state = ref();
    const detail = ref();
    const historyList = ref([]);
    const profileNftList = ref([]);
    const profileNftTotal = ref(0);
    const collectionsList = ref([]);
    const selectTab = ref(route.query?.tab||"nfts");
    const displayMore = ref(false);
    const showMore = ref(true);
    const imgWidth = ref("94px");
    const imgNumber = ref(3);
    const pageNum = ref(0);
    const loadNext = ref();
    const descriptionDom = ref();
    const showLoadMore = ref(false);
    const listLoading = ref(true);
    let ignoreObserver = true;

    const getAccountWalletData = () => {
      getAccountWallet({
        walletAddr: addr,
        pageNum: pageNum.value,
      }).then((res: any) => {
        loading.value = false;
        state.value = res?.data;
        nextTick(() => {
          const dom = descriptionDom.value as HTMLElement;
          if (dom && dom.offsetHeight > 40) {
            showMore.value = false;
            displayMore.value = true;
          }
        });
      });
    };
    getAccountWalletData();

    const collectionNum = ref(0);
    const collectionMore = ref(true);
    const getCollectionMoreData = () => {
      getAccountWalletContracts({
        walletAddr: addr,
        pageNum: collectionNum.value,
      }).then((res: any) => {
        if (res?.data?.length <= 0) {
          collectionMore.value = false;
          return;
        }
        collectionNum.value += 1;
        const collections = res?.data || [];
        collections.forEach((element: any, index: number) => {
          element.checked = false;
          element.pageNum = 0;
          element.pageSize = imgNumber.value * 2;
          element.loading = false;
          element.items = [];
        });
        if (collectionNum.value === 0) {
          collectionsList.value = collections;
        } else {
          collectionsList.value = collectionsList.value.concat(collections);
        }
      });
    };

    const showChooseCollections = ref(false);
    const selectedCollections = ref([]);
    const contractAddr = ref("");
    const showName = ref("");
    const currentOption = [
      {
        name: `${"Current Price"}: ${"high to low"}`,
        id: "curPrice_desc",
        icon: "down",
      },
      {
        name: `${"Current Price"}: ${"low to high"}`,
        id: "curPrice_asc",
        icon: "up",
      },
    ];
    const orderBy = ref(currentOption[0]);

    const getAccountNftsData = () => {
      getAccountNfts({
        walletAddr: addr,
        contractAddr: contractAddr.value,
        pageSize: 15,
        pageNum: pageNum.value,
        orderBy: orderBy.value?.id, // curPrice_desc curPrice_asc
      }).then((res: any) => {
        listLoading.value = false;
        if (res?.data?.length <= 0) {
          showLoadMore.value = false;
          return;
        }
        showLoadMore.value = res?.nextPageNum > 0;
        if (pageNum.value === 0) {
          profileNftList.value = res?.data;
        } else {
          profileNftList.value = profileNftList.value.concat(res?.data);
        }
        pageNum.value += 1;
        profileNftTotal.value = res?.totalSize;
      });
    };
    // getAccountNftsData();

    const relaodData = () => {
      profileNftList.value = [];
      listLoading.value = true;
      contractAddr.value = "";
      showLoadMore.value = false;
      ignoreObserver = true;
      selectedCollections.value.forEach((item: any, index: number) => {
        contractAddr.value += item?.contract;
        if (index < selectedCollections.value.length - 1) {
          contractAddr.value += ",";
        }
      });
      pageNum.value = 0;
      getAccountNftsData();
    };

    watch(selectedCollections, () => {
      relaodData();
    });

    watch(orderBy, () => {
      pageNum.value = 0;
      profileNftList.value = [];
      listLoading.value = true;
      ignoreObserver = true;
      getAccountNftsData();
    });

    const deleteItem = (item: any, index: number) => {
      item.checked = false;
      selectedCollections.value.splice(index, 1);
      profileNftList.value = [];
      relaodData();
    };
    const clearSelect = () => {
      collectionsList.value.forEach((element: any, index: number) => {
        element.checked = false;
      });
      selectedCollections.value = [];
      profileNftList.value = [];
      // relaodData();
    };

    const transferOption = [
      {
        name: "All",
        id: "",
      },
      {
        name: "Bought",
        id: "bought",
      },
      {
        name: "Sold",
        id: "sold",
      },
      {
        name: "Mint",
        id: "mint",
      },
    ];
    const transferType = ref(transferOption[0]);
    const resetParams = () => {
      listLoading.value = true;
      pageNum.value = 0;
      showLoadMore.value = false;
      historyList.value = [];
      profileNftList.value = [];
      ignoreObserver = true;
    };
    watch(selectTab, () => {
      historyReplaceStateQuery({tab: selectTab.value});
      resetParams();
      getFirstData();
    });
    const getFirstData = () => {
      listLoading.value = true;
      if (selectTab.value === "nfts") {
        getAccountNftsData();
      } else {
        getAccountHistoryList();
      }
    };
    

    watch(transferType, () => {
      pageNum.value = 0;
      listLoading.value = true;
      historyList.value = [];
      getAccountHistoryList();
    });

    const getAccountHistoryList = () => {
      getAccountHistory({
        walletAddr: addr,
        pageNum: pageNum.value,
        transferType: transferType.value?.id,
      }).then((res: any) => {
        listLoading.value = false;
        if (res?.data?.length > 0) {
          showLoadMore.value = true;
          pageNum.value += 1;
          historyList.value = historyList.value.concat(res?.data || []);
        } else {
          showLoadMore.value = false;
        }
      });
    };
    getFirstData();
    const handleCopyString = (string = "") => {
      copyString(string);
      Toast("Copy successfully");
    };

    let ob: any;
    onMounted(() => {
      getCollectionMoreData();
      ob = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio === 0) return;
          if (ignoreObserver) {
            ignoreObserver = false;
            return;
          }
          if (selectTab.value === "nfts") {
            getAccountNftsData();
          } else {
            getAccountHistoryList();
          }
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadNext.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });

    return {
      state,
      detail,
      historyList,
      profileNftList,
      profileNftTotal,
      collectionsList,
      selectTab,
      showMore,
      loading,
      kmbNumber,
      getFloat,
      decimalizeNumber,
      amountFormat,
      getLongStringShow,
      copyString,
      handleCopyString,
      // showClick,
      // viewMore,
      imgWidth,
      imgNumber,
      showLoadMore,
      loadNext,
      displayMore,
      descriptionDom,
      listLoading,
      currentOption,
      orderBy,
      showName,
      showChooseCollections,
      selectedCollections,
      contractAddr,
      clearSelect,
      deleteItem,

      transferOption,
      transferType,

      collectionMore,
      getCollectionMoreData,
    };
  },
});
